// Packages
import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { autorun, reaction } from "mobx";
import { inject, observer, disposeOnUnmount } from "mobx-react";
import {
  IonSplitPane,
  IonMenu,
  IonContent,
  IonMenuToggle,
  IonMenuButton,
} from "@ionic/react";
import { NavLink } from "react-router-dom";
// Components
import { getAccessTokenFromLocalStorage } from "../../plugins/localStorage";
// Styles
import FlagSVG from "../../components/svgComponents/FlagSVG";
import { ActiveActionsSVG } from "../../components/svgComponents/ActiveActionsSVG";
import { ActionsSVG } from "../../components/svgComponents/ActionsSVG";
import ProfileSVG from "../../components/svgComponents/ProfileSVG";
import ReviewNReflectSVG from "../../components/svgComponents/ReviewNReflectSVG";
import InspirationSVG from "../../components/svgComponents/InspirationSVG";
import { ActiveInspirationSVG } from "../../components/svgComponents/ActiveInspirationSVG";
import HelpSVG from "../../components/svgComponents/HelpSVG";
import ActiveReviewNReflectSVG from "../../components/svgComponents/ActiveReviewNReflectSVG";
import { ArrowRightOutlined } from "@ant-design/icons";

interface SplitLayoutProps extends RouteProps {
  component: any;
  checkInStore?: any;
  userStore?: any;
  goalStore?: any;
}

@inject("checkInStore", "userStore", "goalStore")
@observer
class SplitLayout extends React.Component<SplitLayoutProps> {
  public getToken = () => {
    return getAccessTokenFromLocalStorage();
  };

  public checkInsAutorun = autorun(() => {
    if (!this.props.checkInStore.checkIns) {
      this.props.checkInStore.getCheckIns();
    }
  });

  @disposeOnUnmount
  public layoutUserReaction = reaction(
    () => this.props.userStore.currentUser.id,
    (userId: any) => {
      this.props.checkInStore.getCheckIns();
    }
  );

  render() {
    const { component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(routeProps) =>
          !this.getToken() ? (
            <Redirect to="/signin" />
          ) : (
            <>
              <IonContent>
                <IonSplitPane contentId="menuContent">
                  {/*  Side Menu  */}
                  <IonMenu
                    contentId="menuContent"
                    menuId="mainMenu"
                    className="no-border-right"
                  >
                    <IonContent>
                      <ul className="nav-menu">
                        <NavLink to="/" className="navbar-link">
                          <div className="DashboardLeftLogo">
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/logomarknew.svg`}
                              className="logoDashboard"
                              alt="Goalden Hour logomark"
                            />
                            <span
                              className="logo-title"
                              style={{
                                marginLeft: "1rem",
                              }}
                            >
                              Goalden Hour
                            </span>
                          </div>
                        </NavLink>
                        <NavLink
                          to="/goals"
                          onClick={() => {
                            this.props.goalStore.updateGoalModal = false;
                            this.props.goalStore.createGoalModal = false;
                          }}
                          className={
                            routeProps.location.pathname.startsWith("/goals")
                              ? "active-navlink"
                              : ""
                          }
                        >
                          <li>
                            <span>
                              {/* {routeProps.location.pathname.startsWith("/goals") ? (
                                <ActiveInspirationSVG />
                              ) : (
                                <InspirationSVG />
                                )} */}
                              <FlagSVG className="active" />
                              Goals
                            </span>
                          </li>
                        </NavLink>
                        <NavLink
                          to="/actions"
                          className={
                            routeProps.location.pathname.startsWith("/actions")
                              ? "active-navlink"
                              : ""
                          }
                        >
                          <li>
                            <span>
                              {routeProps.location.pathname.startsWith(
                                "/actions"
                              ) ? (
                                <ActiveActionsSVG />
                              ) : (
                                <ActionsSVG />
                              )}
                              Actions
                            </span>
                          </li>
                        </NavLink>
                        <NavLink
                          to="/reviews-reflect"
                          className={
                            routeProps.location.pathname.startsWith(
                              "/reviews-reflect"
                            )
                              ? "active-navlink"
                              : ""
                          }
                        >
                          <li>
                            <span>
                              {routeProps.location.pathname.startsWith(
                                "/reviews-reflect"
                              ) ? (
                                <ActiveReviewNReflectSVG />
                              ) : (
                                <ReviewNReflectSVG />
                              )}
                              Review & Reflect
                            </span>
                          </li>
                        </NavLink>
                        {/* <div className="check-in-inline-badge">
                      </div> */}
                        {/* <Badge count={this.props.checkInStore.incompleteCheckIns} className="check-in-badge">
                    </Badge>  */}
                        <NavLink
                          to="/inspiration"
                          className={
                            routeProps.location.pathname.startsWith(
                              "/inspiration"
                            )
                              ? "active-navlink"
                              : ""
                          }
                        >
                          <li>
                            <span>
                              {routeProps.location.pathname.startsWith(
                                "/inspiration"
                              ) ? (
                                <ActiveInspirationSVG />
                              ) : (
                                <InspirationSVG />
                              )}
                              Inspiration
                            </span>
                          </li>
                        </NavLink>
                        <NavLink
                          to="/profile"
                          className={
                            routeProps.location.pathname.startsWith("/profile")
                              ? "active-navlink"
                              : ""
                          }
                        >
                          <li>
                            <span>
                              <ProfileSVG className="active" />
                              Profile
                            </span>
                          </li>
                        </NavLink>
                        <NavLink
                          to="/support"
                          className={
                            routeProps.location.pathname.startsWith("/support")
                              ? "active-navlink"
                              : ""
                          }
                        >
                          <li>
                            <span>
                              <HelpSVG className="active" />
                              Help
                            </span>
                          </li>
                        </NavLink>
                      </ul>
                    </IonContent>
                    <div className="bubble-container">
                      <div className="inner-circle-one">
                        <div className="inner-circle-two">
                          <div className="inner-circle-three"></div>
                        </div>
                      </div>
                    </div>
                  </IonMenu>
                  {/* Main Content */}
                  <div id="menuContent" style={{ display: "flex" }}>
                    <IonMenuButton
                      style={{
                        zIndex: 100,
                        position: "fixed",
                        color: "white",
                        marginTop: "3.2rem",
                        borderRadius: "0 50% 50% 0",
                        backgroundColor: "#2b2930",
                        width: "2.5rem",
                        height: "2.5rem",
                      }}
                    />
                    <div>
                      <Component {...routeProps} />
                    </div>
                  </div>
                </IonSplitPane>
              </IonContent>
            </>
          )
        }
      />
    );
  }
}

export default SplitLayout;
